import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import withWidth from '@material-ui/core/withWidth';
import { compose } from 'utils';

import { RichText } from 'prismic-reactjs';
import linkResolver from 'utils/linkResolver';
import htmlSerializer from 'utils/htmlSerializer';

import DottedUnderline from 'images/dotted_underline.svg';

import OVORectangle from 'images/ovo_rectangle.svg';

const styles = theme => ({
  wrapper: {
    paddingLeft: '0',
    paddingRight: '0',
  },
  valueWrapper: {
    width: 'auto',
    maxWidth: '15em',
    margin: '0 auto 2em',
    textAlign: 'center',
  },
  title: {
    color: theme.palette.text.green,
  },
  img: {
    maxWidth: '100%',
  },
});

function ThreeColumn(props) {
  const { data, fields, classes } = props;
  return (
    <Box className={classes.wrapper}>
      <Container maxWidth="lg">
        {data.title?.text && (
          <Box
            component="div"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            mb={2}
          >
            <Typography
              component="h2"
              variant="h2"
              align="center"
              className={classes.title}
            >
              {data.title.text}
            </Typography>
          </Box>
        )}
        {data.caption && data.caption.text && (
          <Grid container justify="center">
            <Grid item md={8}>
              <Typography
                variant="body1"
                component="p"
                align="center"
                color="textPrimary"
              >
                {data.caption.text}
              </Typography>
            </Grid>
          </Grid>
        )}

        {fields && (
          <Box mt={3}>
            <Grid container justify="center" alignItems="center" spacing={4}>
              {fields.map((item, key) => {
                return (
                  <Grid item md={4} key={key}>
                    <Box bgcolor="background.default">
                      <Box mb={2} className={classes.valueWrapper}>
                        <OVORectangle />
                      </Box>
                      {item?.body?.raw && (
                        <Box textAlign="center">
                          <RichText
                            render={item.body.raw}
                            htmlSerializer={htmlSerializer}
                          />
                        </Box>
                      )}
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        )}
        {data.footer_link && data.footer_link.raw && data.footer_link.raw.url && (
          <Box display="flex" justifyContent="center">
            <a
              href={data.footer_link.raw.url}
              className="RichTextBlocklink"
              target="_blank"
              rel="noreferrer"
            >
              {data.footer_link_text ? data.footer_link_text : ''}
            </a>
          </Box>
        )}
      </Container>
    </Box>
  );
}

ThreeColumn.propTypes = {
  data: PropTypes.object.isRequired,
  fields: PropTypes.array.isRequired,
  classes: PropTypes.object,
  width: PropTypes.string,
};

export default compose(withWidth())(withStyles(styles)(ThreeColumn));
